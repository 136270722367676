import React from 'react';
import { Container } from 'reactstrap';
import { GET_GLOBAL_TRIGGERS, UPDATE_TRIGGER, newTrigger } from 'requests/triggers';
import { Trigger } from 'interfaces/triggers';
import { useList } from 'utils/useList';
import { useParams } from 'react-router';
import Edit from 'views/Containers/Triggers/Edit';
import { SlideOverlay } from 'components';

export interface Request {
    getVariables: Trigger[];
}
export interface Mutation {
    variableData: Trigger;
}

function VariablesView() {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, Trigger>({
        parent: 'network',
        type: 'trigger',
        request: GET_GLOBAL_TRIGGERS,
        mutation: UPDATE_TRIGGER,
        global: true,
        createItem: newTrigger,
        headers: [
            { name: 'name', label: 'Name' },
            { name: 'trigger', label: 'Trigger' },
        ],
    });

    return (
        <Container>
            <h1>Triggers</h1>
            {list}
            {id && (
                <SlideOverlay size='large'>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default VariablesView;
