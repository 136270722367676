import React from 'react';
import { Container } from 'reactstrap';
import { useList } from 'utils';
import { ContainerType } from 'interfaces/containers';
import { GET_USERS, UPDATE_USER } from 'requests/users';

import { User } from 'interfaces/users';

import { useParams } from 'react-router';
import Edit from './Edit';
import { SlideOverlay } from 'components';

// interface Mutate {
//     containerData: ContainerType;
// }
interface Request {
    getUsers: ContainerType;
}

const Users = () => {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, User>({
        title: 'Local USers',
        parent: 'admin',
        type: 'user',
        request: GET_USERS,
        mutation: UPDATE_USER,
        headers: [
            { name: 'name', label: 'Name' },
            { name: 'id', label: 'id' },
        ],
        deleteable: false,
    });
    return (
        <Container>
            <h1>Users</h1>
            {list}
            {id && (
                <SlideOverlay>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
};

export default Users;
