import React from 'react';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router';
import { useList } from 'utils';
import { ContainerType } from 'interfaces/containers';
import { GET_CONTAINERS, UPDATE_CONTAINER, newItem } from 'requests/containers';
// interface Props {
//     id: string;
// }
// interface Mutate {
//     containerData: ContainerType;
// }
interface Request {
    getContainers: ContainerType;
}

function TagsView() {
    const history = useHistory();
    const list = useList<Request, ContainerType>({
        parent: 'containers',
        type: 'container',
        request: GET_CONTAINERS,
        mutation: UPDATE_CONTAINER,
        createItem: newItem,
        onClick: clickId => {
            localStorage.setItem('containerId', clickId);
            history.push(`/containers/${clickId}/dashboard`);
        },
        headers: [
            { name: 'name', label: 'Name' },
            { name: 'type', label: 'Type' },
        ],
    });

    return (
        <Container>
            <h1>Containers</h1>
            {list}
        </Container>
    );
}

export default TagsView;
