import React from 'react';
import { Container } from 'reactstrap';
import { Tag } from 'interfaces/tags';
import { useList } from 'utils/useList';
import { useParams } from 'react-router';
import Edit from 'views/Containers/Tags/Edit';
import { SlideOverlay } from 'components';
import { GET_GLOBAL_TAGS, UPDATE_TAGS, newItem } from 'requests/tags';

export interface Request {
    getTags: Tag[];
}
export interface MutateTags {
    tagData: Tag;
}

function TagsView() {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, Tag>({
        parent: 'network',
        type: 'tag',
        request: GET_GLOBAL_TAGS,
        mutation: UPDATE_TAGS,
        global: true,
        createItem: newItem,
        headers: [{ name: 'name', label: 'Name' }],
    });

    return (
        <Container>
            <h1>Tags</h1>
            {list}
            {id && (
                <SlideOverlay size='large'>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default TagsView;
