import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Card, Input, AddButton, Button, RemoveButton, Tag, Popup } from 'components';
import bidders from './bidders';

interface Props {
    state: any;
}

export const Targeting = ({ state }: Props) => {
    const [popup, setPopup] = useState(false);
    const [currentBidder, setBidder] = useState('');
    const targeting = state.current.prebid.targeting || {};

    const availableBidders = Object.keys(bidders);
    const targetedBidders = Object.keys(targeting);

    const closeTargeting = () => {
        setBidder('');
        state.setAtPath('prebid.targeting', targeting);
        setPopup(false);
    };

    const newTargeting = (id: string, name: string) => ({
        id: id,
        name: name,
        keyValues: [{ key: '', value: '' }],
    });

    return (
        <>
            <Popup show={popup} callback={closeTargeting}>
                {targeting[currentBidder] ? (
                    <>
                        <h3>{targeting[currentBidder].name}</h3>
                        <hr />
                        <Row>
                            <Col md={5}>
                                <label>Key</label>
                            </Col>
                            <Col md={5}>
                                <label>Value</label>
                            </Col>
                        </Row>
                        {targeting[currentBidder].keyValues.map((item, i) => {
                            return (
                                <Row key={item} style={{ marginBottom: '10px' }}>
                                    <Col md={5}>
                                        <Input
                                            state={state}
                                            name={`prebid.targeting.${currentBidder}.keyValues[${i}].key`}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <Input
                                            state={state}
                                            name={`prebid.targeting.${currentBidder}.keyValues[${i}].value`}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <RemoveButton
                                            onClick={() =>
                                                state.splice(`prebid.targeting.${currentBidder}.keyValues`, i)
                                            }
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                        <AddButton
                            size='small'
                            onClick={() =>
                                state.push(`prebid.targeting.${currentBidder}.keyValues`, {
                                    key: '',
                                    value: '',
                                })
                            }
                        ></AddButton>
                    </>
                ) : (
                    <>
                        <h2>Select bidder</h2>
                        {availableBidders.map(bidder => {
                            if (!targetedBidders.includes(bidder) && bidders[bidder].targeting)
                                return (
                                    <Card
                                        key={bidder}
                                        type={'clickable'}
                                        onClick={() => {
                                            state.setAtPath(
                                                `prebid.targeting.${bidder}`,
                                                newTargeting(bidder, bidder),
                                            );
                                        }}
                                    >
                                        {bidders[bidder].bidder}
                                    </Card>
                                );
                            else return <div key={bidder}></div>;
                        })}
                    </>
                )}
            </Popup>

            <Card title='Targeting'>
                <Row>
                    <Col>
                        {targetedBidders ? (
                            targetedBidders.map(bidder => {
                                return (
                                    <Tag
                                        key={bidder}
                                        onClick={() => {
                                            setBidder(bidder);
                                            setPopup(!popup);
                                        }}
                                        remove={() => state.delete(`prebid.targeting.${bidder}`)}
                                    >
                                        {bidder}
                                    </Tag>
                                );
                            })
                        ) : (
                            <>No targeting</>
                        )}

                        <Button
                            onClick={() => {
                                setPopup(!popup);
                            }}
                        >
                            Add targeting
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
};
