import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router';
import { Tag } from 'interfaces/tags';
import { useList } from 'utils/useList';
import Edit from './Edit';
import { GET_TAGS_BY_CONTAINER, UPDATE_TAGS, newItem, GET_TAG_LIST, GET_GLOBAL_TAGS } from 'requests/tags';
import { SlideOverlay } from 'components';
import AddRemoveGlobalsView from 'containers/AddRemoveGlobals';

export interface Request {
    getTags: Tag[];
}
export interface Mutation {
    tagData: Tag;
}

function TagsView() {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, Tag>({
        parent: 'containers',
        type: 'tag',
        request: GET_TAGS_BY_CONTAINER,
        mutation: UPDATE_TAGS,
        createItem: newItem,
        headers: [{ name: 'name', label: 'Name' }],
    });

    return (
        <Container>
            <h1>Tags</h1>
            {list}
            <AddRemoveGlobalsView<Request>
                type={'tag'}
                current={GET_TAG_LIST}
                mutation={UPDATE_TAGS}
                request={GET_GLOBAL_TAGS}
            />
            {id && (
                <SlideOverlay size='large'>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default TagsView;
