import styled from 'styled-components';
import { Row } from 'reactstrap';

export const shadows = {
    deep: 'box-shadow: rgba(116,129,141,0.2) 0px 5px 10px -5px',
    veryDeep: 'box-shadow: rgba(74, 87, 99, 0.35) 0px 24px 40px -20px',
    shallow: 'box-shadow: rgba(116, 129, 141, 0.1) 0px 2px 3px 0px',
    insetShallow: 'box-shadow: inset 0px 1px 3px 0px rgba(116, 129, 141, 0.1)',
    colored: 'box-shadow: 0px 5px 15px #5692ff82',
};

export const sidebar = {
    menuHolder: '340px',
    sideMenu: '90px',
    menu: '250px',
};

export const colors = {
    accentLight: '#8184a9',
    accentMedium: '#4d506f',
    accentDark: '#191d23',
    accentVeryDark: '#12161b',
    lightPrimary: '#a0c1ff',
    primary: '#4e9ef5',
    lightGrey: '#E6ECF1',
    mediumGrey: '#c6c7cc',
    darkGrey: '#676a6c',
    background: '#F5F7F9',
    windowBackground: '#FEFEFF',
    error: '#ea5546',
    warning: '#fddee1',
    info: '#cce3ff',
};

export const Holders = {
    window: `
    border: 1px solid rgba(0,0,0,.12);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    background: ${colors.windowBackground};
    overflow: hidden;
    ${shadows.deep};
    `,
};

export const CodeHolder = styled.div`
    border-radius: 4px;
    overflow: hidden;
    background: #f0f1f1;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
    margin-bottom: 15px;

    .header {
        font-size: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
        padding: 10px;
        background: #f9f9f9;
        font-weight: bold;
        color: #787f7f;
    }
`;

export const TitleInput = styled.input`
    border: none;
    background: none;
    font-family: 'Inter UI', sans-serif;
    padding: 10px 0;
    margin: 20px 0 30px;
    border-bottom: 1px solid ${colors.lightGrey};
    width: 100%;
    color: ${colors.darkGrey};
    font-weight: 700;
    font-size: 120%;
`;

export const DeletableRow = styled(Row)`
    align-items: center;
    padding: 5px 0;
`;

export const Theme = styled.div`
    @import url('https://rsms.me/inter/inter-ui.css');
    height: 100%;
    background: transparent;

    @keyframes slideIn {
        0% {
            right: -100%;
        }
        100% {
            right: 0;
        }
    }

    @keyframes slideOut {
        0% {
            right: 0;
        }
        100% {
            right: -100%;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes scaleIn {
        0% {
            opacity: 0;
            transform: translate3d(0, 0, -50px);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes scaleOut {
        0% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
        100% {
            opacity: 0;
            transform: translate3d(0, 0, -50px);
        }
    }

    /* ---- DEFAULTS ---- */

    img {
        width: 100%;
    }

    body {
        background-color: transparent;
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: 'Inter UI', sans-serif;
        color: ${colors.accentMedium};
        line-height: 100%;
        font-weight: 700;
        margin-bottom: 0.2em;
        display: block;
        transition: all 0.25s ease-in-out;
        width: 100%;
    }

    h1 {
        text-transform: uppercase;
        font-size: 200%;
        margin: 1em 0 1em;
    }
    h2 {
        font-size: 120%;
        color: ${colors.darkGrey};
        padding: 10px 0 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
    }
    h3 {
        font-size: 110%;
        margin-top: 20px;
    }
    h4 {
        font-size: 80%;
        color: #c5c5c5;
        text-transform: uppercase;
        text-align: right;
        margin: 10px 0;
    }

    a,
    a:visited {
        color: #787a7a;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
    }

    a:hover {
        color: ${colors.primary};
    }

    p {
        color: #787a7a;
    }
    b {
        font-weight: bold;
    }
    hr {
        display: block;
        border: none;
        height: 1px;
        background: #d2d2d2;
        margin: 20px 0;
    }
    form {
        display: block;
        float: left;
        width: 100%;
    }
    textarea {
        outline-color: rgba(0, 0, 0, 0);
    }

    /* ---- Inputs -.--- */

    label {
        /* text-transform: uppercase; */
        font-size: 13px;
        margin: 10px 0px 5px;
        color: #4c5152;
    }

    .custom-switch label {
        text-transform: none;
        font-size: inherit;
        margin: 0;
        color: #4c5152;
    }

    table {
        width: 100%;
    }

    tr {
        border-bottom: 1px solid ${colors.lightGrey};
    }

    td,
    th {
        padding: 10px;
    }
    th {
        color: #c1c1c1;
        font-weight: bold;
    }

    pre[class*='language-'] {
        margin: 0;
    }

    .listIcon {
        font-size: 24px;
        margin: 0px 10px;
        color: ${colors.mediumGrey};
    }
`;

export const ButtonStyle = `
    padding: 5px 20px; 
    transition: all .3s;
    background: ${colors.primary};
    color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0,0,0,.2), inset 0px 1px 0px rgba(255,255,255,.4);
    display: flex;
    align-items: center;
    width: auto;

    &:disabled {
        opacity: 0.6;
    }
    `;

export const customTagStyles = {
    option: provided => ({
        ...provided,
        cursor: 'pointer',
    }),
    input: provided => ({
        ...provided,
        padding: '10px',
    }),
    multiValue: styles => {
        return {
            ...styles,
            backgroundColor: colors.primary,
            borderRadius: '5px',
            border: '1px solid rgba(0,0,0,.2)',
            boxShadow: '0px 2px 3px rgba(0, 0, 0, .2), inset 0px 1px 0px rgba(255, 255, 255, .4)',
        };
    },
    multiValueLabel: styles => ({
        ...styles,
        color: 'white',
        padding: '8px',
        marginLeft: '10px',
    }),
    multiValueRemove: styles => ({
        ...styles,
        color: colors.lightPrimary,
        padding: '0 10px',
        fontSize: '16px',
        cursor: 'pointer',
        '> svg': {
            width: '20px',
            height: '20px',
        },
        ':hover': {
            backgroundColor: colors.lightPrimary,
            color: 'white',
        },
    }),
};
