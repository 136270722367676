const bidders = {
    adagio: {
        targeting: false,
        bidder: 'adagio',
        params: {
            organizationId: 'text',
            site: 'text',
            placement: 'text',
            adUnitElementId: 'text',
            pagetype: 'text',
        },
    },

    adform: {
        targeting: false,
        bidder: 'adform',
        params: {
            mid: 'number',
        },
    },

    adnuntius: {
        targeting: false,
        bidder: 'adnuntius',
        params: {
            auId: 'text',
        },
    },

    appnexus: {
        targeting: true,
        bidder: 'appnexus',
        params: {
            placementId: 'number',
        },
    },

    brightcom: {
        targeting: false,
        bidder: 'brightcom',
        params: {
            publisherId: 'number',
        },
    },

    criteo: {
        targeting: false,
        bidder: 'criteo',
        params: {
            zoneId: 'number',
        },
    },

    emx_digital: {
        targeting: false,
        bidder: 'emx_digital',
        params: {
            tagid: 'text',
        },
    },

    gamma: {
        targeting: false,
        bidder: 'gamma',
        params: {
            siteId: 'number',
            zoneId: 'number',
        },
    },

    grid: {
        targeting: false,
        bidder: 'grid',
        params: {
            uid: 'number',
        },
    },

    holid: {
        targeting: false,
        bidder: 'holid',
        params: {
            adUnitID: 'text',
        },
    },

    improvedigital: {
        targeting: false,
        bidder: 'improvedigital',
        params: {
            placementId: 'number',
            publisherId: 'number',
        },
    },

    ix: {
        targeting: false,
        bidder: 'ix',
        params: {
            siteId: 'text',
        },
    },

    kobler: {
        targeting: false,
        bidder: 'kobler',
        params: {},
    },

    medianet: {
        targeting: false,
        bidder: 'medianet',
        params: {
            cid: 'text',
            crid: 'text',
        },
    },
    minutemedia: {
        targeting: false,
        bidder: 'minutemedia',
        params: {
            placementId: 'text',
            org: 'text',
        },
    },
    nextMillennium: {
        targeting: false,
        bidder: 'nextMillennium',
        params: {
            placement_id: 'text',
        },
    },

    nexx360: {
        targeting: false,
        bidder: 'nexx360',
        params: {
            account: 'text',
            tagId: 'text',
        },
    },

    onemobile: {
        targeting: false,
        bidder: 'onemobile',
        params: {
            dcn: 'text',
            pos: 'text',
        },
    },

    onetag: {
        targeting: false,
        bidder: 'onetag',
        params: {
            pubId: 'text',
        },
    },

    pubmatic: {
        targeting: false,
        bidder: 'pubmatic',
        params: {
            publisherId: 'text',
            adSlot: 'text',
        },
    },

    richaudience: {
        targeting: false,
        bidder: 'richaudience',
        params: {
            pid: 'text',
            supplyType: 'text',
        },
    },

    rubicon: {
        targeting: false,
        bidder: 'rubicon',
        params: {
            accountId: 'text',
            siteId: 'text',
            zoneId: 'text',
        },
    },

    sharethrough: {
        targeting: false,
        bidder: 'sharethrough',
        params: {
            pkey: 'text',
        },
    },

    smartadserver: {
        targeting: false,
        bidder: 'smartadserver',
        params: {
            networkId: 'number',
            siteId: 'number',
            pageId: 'number',
            formatId: 'number',
        },
    },

    spotx: {
        targeting: false,
        bidder: 'spotx',
        params: {
            channel_id: 'text',
            ad_unit: 'text',
        },
    },

    triplelift: {
        targeting: false,
        bidder: 'triplelift',
        params: {
            inventoryCode: 'text',
        },
    },

    widespace: {
        targeting: false,
        bidder: 'widespace',
        params: {
            sid: 'text',
        },
    },

    telaria: {
        targeting: false,
        bidder: 'telaria',
        params: {
            adCode: 'text',
            supplyCode: 'text',
        },
    },
};

export default bidders;
