import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Card, Loader } from 'components';
import { useQuery, useMutation, DocumentNode } from '@apollo/client';
import { Button, SlideOverlay } from 'components';
import { useParams } from 'react-router';

interface Props {
    type: string;
    current: DocumentNode;
    mutation: DocumentNode;
    request: DocumentNode;
}

function AddRemoveGlobalsView<RequestType>({ type, mutation, request, current }: Props) {
    const namedType = type.charAt(0).toUpperCase() + type.substring(1).toLowerCase();
    const [show, setShow] = useState(false);
    const { containerId } = useParams<{ containerId: string }>();

    const query = useQuery<RequestType>(request);

    const queryCurrentList = useQuery<RequestType, { containerId: string }>(current, {
        variables: { containerId: containerId },
    });

    const [save] = useMutation<RequestType, any>(mutation, {
        refetchQueries: [request],
    });

    const change = (id: string, addRemove: string) => {
        save({
            variables: { [`${type}Data`]: [{ id, [addRemove]: { containers: [containerId] } }] },
        });
    };

    if (query.loading || !query.data || !queryCurrentList.data) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    const currentList = queryCurrentList.data[`get${namedType}s`].map(item => item.id);
    const globalList = query.data[`get${namedType}s`].map(item => item.id);

    const activeTags = currentList.reduce((acc, curr) => {
        if (globalList.includes(curr)) {
            acc.push(curr);
        }
        return acc;
    }, []);

    return (
        <Container>
            <Button onClick={() => setShow(true)}>Add / Remove global {type}</Button>
            {show && (
                <SlideOverlay closeFunction={() => setShow(false)}>
                    {query.data[`get${namedType}s`].map(item => {
                        const active = activeTags.includes(item.id);
                        return (
                            <Card
                                style={{ cursor: 'pointer' }}
                                type={active ? 'active' : ''}
                                key={item.id}
                                onClick={() => change(item.id, active ? 'remove' : 'add')}
                            >
                                {item.name}
                            </Card>
                        );
                    })}
                </SlideOverlay>
            )}
        </Container>
    );
}

export default AddRemoveGlobalsView;
