import { gql } from '@apollo/client';
import { Trigger } from 'interfaces/triggers';
import shortid from 'shortid';

export const GET_TRIGGER_BY_ID = gql`
    query getTrigger($id: ID, $containerId: String) {
        stateFilter @client @export(as: "state")
        getVariables(filter: { state: active, containerId: $containerId }) {
            id
            name
            type
            variable
        }
        getTrigger(id: $id) {
            id
            name
            state
            criteria
            trigger
            global
            containers {
                id
                name
            }
            tags {
                id
                name
            }
        }
        getTags(filter: { state: active, containerId: $containerId }) {
            id
            name
            content
            type
            global
        }
    }
`;

export const GET_GLOBAL_TRIGGERS = gql`
    query getTriggerList($state: State) {
        stateFilter @client @export(as: "state")
        network @client
        getTriggers(filter: { state: $state, global: true }) {
            id
            name
            state
            trigger
            global
        }
    }
`;

export const GET_TRIGGER_LIST = gql`
    query getTriggerList($state: State, $containerId: String) {
        stateFilter @client @export(as: "state")
        network @client
        getTriggers(filter: { state: $state, containerId: $containerId }) {
            id
            name
            state
            trigger
            global
        }
    }
`;

export const UPDATE_TRIGGER = gql`
    mutation editTriggers($triggerData: [TriggerInput]) {
        editTriggers(triggerData: $triggerData) {
            id
            name
            state
            trigger
            criteria
            global
        }
    }
`;

export const newTrigger = (name: string, containerArray: string[], global): Trigger => {
    const criteriaId = shortid.generate();
    const trigger: Trigger = {
        name: name,
        state: 'active',
        trigger: 'browser.state.pageview',
        add: {
            containers: containerArray,
        },
        criteria: [
            {
                id: criteriaId,
                isGroup: true,
                operand: 'and',
                values: [],
            },
        ],
    };

    if (global) {
        trigger['global'] = true;
    }

    return trigger;
};

export const newCriteria = () => ({
    id: shortid.generate(),
    source: '',
    attribute: '',
    operand: '',
    value: '',
});

export const newGroup = () => ({
    id: shortid.generate(),
    isGroup: true,
    operand: 'and',
    values: [],
});
