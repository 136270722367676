import React from 'react';
import { useParams } from 'react-router';
import { Container, Row, Col } from 'reactstrap';
import { Block } from 'components';
import { useQuery, useMutation } from '@apollo/client';
import { ContainerType } from 'interfaces/containers';
import { Button } from 'components';

import { notify } from 'react-notify-toast';
import { GET_CONTAINER_BY_ID, UPDATE_CONTAINER } from 'requests/containers';
import { clean, useStateHandler } from 'utils';
import { CodeHolder } from 'style';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

interface Props {
    id: string;
}
interface Mutate {
    containerData: ContainerType[];
}
interface Request {
    getContainer: ContainerType;
}

const Dashboard = () => {
    const highlight = code => {
        console.log('CODE', code);
        return Prism.highlight(code, Prism.languages.javascript, 'js');
    };

    // const [code, setCode] = useState('');
    const { containerId } = useParams<{ containerId: string }>();
    const query = useQuery<Request, Props>(GET_CONTAINER_BY_ID, {
        variables: { id: containerId },
    });
    const state = useStateHandler<ContainerType>(query.data?.getContainer);
    const [save, mutation] = useMutation<Request, Mutate>(UPDATE_CONTAINER, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });
    // useEffect(() => {
    //     if (state.current) setCode(JSON.stringify(state.current, null, 3));
    // }, [state]);
    // if (query.loading || !state.current.data) return <Loader />;
    if (query.error) return <p>Error :(</p>;
    console.log(state.current);
    return (
        <Container>
            <Row>
                <Col md={12}>
                    <Block title={state.current.name}>
                        <Row>
                            <Col>
                                <CodeHolder>
                                    <div className='header'>Script</div>
                                    <Editor
                                        value={JSON.stringify(state?.current) || ''}
                                        name=''
                                        tabSize={4}
                                        onValueChange={changedCode => {
                                            console.log('CC', changedCode);
                                            state?.set(JSON.parse(changedCode));
                                        }}
                                        highlight={highlight}
                                        padding={10}
                                        style={{
                                            fontFamily: '"Fira code", "Fira Mono", monospace',
                                            fontSize: 14,
                                        }}
                                    />
                                </CodeHolder>
                            </Col>
                        </Row>
                    </Block>
                </Col>
                <Button
                    loader={mutation.loading}
                    onClick={() => {
                        console.log(state.current);
                        save({ variables: { containerData: [clean(state.current)] } });
                    }}
                >
                    Save
                </Button>
            </Row>
        </Container>
    );
};

export default Dashboard;
