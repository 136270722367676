import React from 'react';
import { Container } from 'reactstrap';
import { GET_VARIABLES_LIST, UPDATE_VARIABLE, newVariable, GET_GLOBAL_VARIABLES } from 'requests/variables';
import { Variable } from 'interfaces/variables';
import { useList } from 'utils/useList';
import { useParams } from 'react-router';
import Edit from './Edit';
import { SlideOverlay } from 'components';
import AddRemoveGlobalsView from 'containers/AddRemoveGlobals';
export interface Request {
    getVariables: Variable[];
}
export interface MutateVariables {
    variableData: Variable;
}

function VariablesView() {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, Variable>({
        title: 'Local variables',
        parent: 'containers',
        type: 'variable',
        request: GET_VARIABLES_LIST,
        mutation: UPDATE_VARIABLE,
        createItem: newVariable,
        headers: [
            { name: 'name', label: 'Name' },
            { name: 'variable', label: 'Variable' },
            { name: 'default', label: 'Default' },
        ],
    });
    return (
        <Container>
            <h1>Variables</h1>
            {list}
            <AddRemoveGlobalsView<Request>
                type={'variable'}
                current={GET_VARIABLES_LIST}
                mutation={UPDATE_VARIABLE}
                request={GET_GLOBAL_VARIABLES}
            />
            {id && (
                <SlideOverlay>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default VariablesView;
