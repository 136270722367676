import React from 'react';
import List from 'containers/List';
import { useQuery, useMutation, DocumentNode } from '@apollo/client';
import { useParams } from 'react-router';

export const useList = function <RequestType, Item>(options: {
    title?: string;
    parent: string;
    type: string;
    request: DocumentNode;
    mutation: DocumentNode;
    createItem?: (name: string, containerArray: string[], global: boolean) => Item;
    headers?: { name: string; label: string }[];
    sortable?: boolean;
    onClick?: (clickId: string) => void;
    global?: boolean;
    deleteable?: boolean;
}) {
    const { containerId } = useParams<{ containerId: string }>();

    const query = useQuery<RequestType, { containerId: string }>(options.request, {
        variables: { containerId: containerId },
    });

    const [save, mutation] = useMutation<RequestType>(options.mutation, {
        refetchQueries: [options.request],
    });
    const dataName = 'get' + options.type.charAt(0).toUpperCase() + options.type.slice(1) + 's';
    const clickUrl =
        options.parent === 'containers'
            ? `/${options.parent}/${containerId}/${options.type}s/`
            : `/${options.parent}/${options.type}s/`;

    return (
        <List
            filters={true}
            title={options.title || ''}
            sortable={options.sortable}
            dataName={options.type}
            query={query}
            mutation={mutation}
            save={save}
            newItem={options.createItem}
            clickUrl={clickUrl}
            listData={query.data?.[dataName]}
            listHeaders={options.headers}
            onClick={options.onClick}
            containerId={containerId}
            global={options.global}
            deleteable={options.deleteable}
        />
    );
};
