import React from 'react';
import { Form, Button, Loader, CustomInput } from 'components';
import { Container } from 'reactstrap';

import { UPDATE_USER, GET_USER_BY_ID } from 'requests/users';
import { User } from 'interfaces/users';
import { useQuery, useMutation } from '@apollo/client';
import { notify } from 'react-notify-toast';
import { useStateHandler } from 'utils';

interface Props {
    id: string;
}

export interface MutateUser {
    userData: User;
}
export interface RequestUser {
    getUsers: User;
    getContainers: Container[];
}

export default function VariableEdit({ id }: Props) {
    // const request = Variable.useRequest({ id: id });
    const query = useQuery<RequestUser, Props>(GET_USER_BY_ID, { variables: { id: id } });
    const state = useStateHandler<User>(query.data?.getUsers[0]);
    const [save, mutation] = useMutation<RequestUser, MutateUser>(UPDATE_USER, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });
    const containers = query.data?.getContainers || [];

    function setAllAtContainer(containerId: string, value: boolean) {
        state.setMultipleAtPath([
            { [`containerPermissions.${containerId}.read`]: value },
            { [`containerPermissions.${containerId}.write`]: value },
        ]);
    }

    if (query.loading || !state.current) return <Loader />;
    if (query.error) return <p>Error :(</p>;
    const isAdmin = state.current.roles?.includes('admin');

    return (
        <Container>
            <Form state={state}>
                <h3>{state.current.name}</h3>
                <p>{state.current.roles?.join(', ')}</p>
                <table>
                    <thead>
                        <tr>
                            <th>Container</th>
                            <th>Read</th>
                            <th>Write</th>
                        </tr>
                    </thead>
                    <tbody>
                        {containers.map(container => (
                            <tr key={container.id}>
                                <td>{container.name}</td>
                                <td>
                                    <CustomInput
                                        onChange={e => {
                                            if (
                                                e.target.checked === false &&
                                                state.current.containerPermissions[container.id].write ===
                                                    true
                                            )
                                                setAllAtContainer(container.id, false);
                                        }}
                                        disabled={isAdmin}
                                        state={state}
                                        type='switch'
                                        id={`${container.id}:read`}
                                        name={`containerPermissions.${container.id}.read`}
                                        label=''
                                    />
                                </td>
                                <td>
                                    <CustomInput
                                        onChange={e => {
                                            if (
                                                e.target.checked === true &&
                                                state.current.containerPermissions[container.id].read ===
                                                    false
                                            )
                                                setAllAtContainer(container.id, true);
                                        }}
                                        disabled={isAdmin}
                                        state={state}
                                        type='switch'
                                        id={`${container.id}:write`}
                                        name={`containerPermissions.${container.id}.write`}
                                        label=''
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Button
                    loader={mutation.loading}
                    onClick={() => {
                        save({
                            variables: {
                                userData: {
                                    id: state.current.id,
                                    containerPermissions: state.current.containerPermissions,
                                },
                            },
                        });
                    }}
                >
                    Save
                </Button>
            </Form>
        </Container>
    );
}
