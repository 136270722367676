import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router';
import Edit from './Edit';
import { SlideOverlay } from 'components';
import { useList } from 'utils/useList';
import { Language } from 'interfaces/languages';
import { GET_LANGUAGES, UPDATE_LANGUAGE, newItem } from 'requests/languages';

export interface Request {
    getLanguages: Language[];
}
export interface Mutation {
    languageData: Language;
}

function LanguageView() {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, Language>({
        parent: 'network',
        type: 'language',
        request: GET_LANGUAGES,
        mutation: UPDATE_LANGUAGE,
        createItem: newItem,
        headers: [
            { name: 'name', label: 'Name' },
            { name: 'languageCode', label: 'Language Code' },
        ],
    });

    return (
        <Container>
            <h1>Language</h1>
            {list}
            {id && (
                <SlideOverlay size='large'>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default LanguageView;
